import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import awsconfig from '../../aws-exports';
import gql from 'graphql-tag';
import { addPlayer, addTurn, createGame, createMessage, startGame } from '../../graphql/mutations';
import { updatedGame, newMessage } from '../../graphql/subscriptions';
import { getGame, getMessages } from '../../graphql/queries';
import {
  ActionType,
  AddPlayerMutation,
  AddPlayerMutationVariables,
  AddTurnMutation,
  AddTurnMutationVariables,
  CreateGameMutation,
  CreateGameMutationVariables,
  CreateMessageMutation,
  CreateMessageMutationVariables,
  GetGameQuery,
  GetGameQueryVariables,
  GetMessagesQuery,
  GetMessagesQueryVariables,
  NewMessageSubscription,
  NewMessageSubscriptionVariables,
  Room,
  StartGameMutation,
  StartGameMutationVariables,
  UpdatedGameSubscription,
  UpdatedGameSubscriptionVariables,
} from '../../API';

const client = new AWSAppSyncClient({
  url: awsconfig.aws_appsync_graphqlEndpoint,
  region: awsconfig.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.API_KEY,
    apiKey: awsconfig.aws_appsync_apiKey,
  },
});

const mutateCreateGame = (
  minPlayers: number,
  numCrew: number,
  numTraitors: number,
  maxHealth: number,
  startingDamage: number,
  numTurns: number,
  numScans: number,
  numRooms: number,
): Promise<{ data: CreateGameMutation }> => {
  const variables: CreateGameMutationVariables = {
    minPlayers,
    numCrew,
    numTraitors,
    maxHealth,
    startingDamage,
    numTurns,
    numScans,
    numRooms,
  };
  return client.mutate({
    mutation: gql(createGame),
    variables,
  });
};

const mutateStartGame = (gameId: string): Promise<{ data: StartGameMutation }> => {
  const variables: StartGameMutationVariables = {
    gameId,
  };
  return client.mutate({
    mutation: gql(startGame),
    variables,
  });
};

const mutateAddTurn = (
  gameId: string,
  playerId: string,
  ready: boolean,
  actionType: ActionType,
  nextRoom: Room | undefined,
  hitList: string[],
  airlockList: string[],
): Promise<{ data: AddTurnMutation }> => {
  const variables: AddTurnMutationVariables = {
    gameId,
    playerId,
    ready,
    action: {
      actionType,
      nextRoom,
      hitList,
      airlockList,
    },
  };
  return client.mutate({
    mutation: gql(addTurn),
    variables,
  });
};

const mutateAddPlayer = (gameId: string, playerName: string): Promise<{ data: AddPlayerMutation }> => {
  const variables: AddPlayerMutationVariables = {
    gameId,
    playerName,
  };
  return client.mutate({
    mutation: gql(addPlayer),
    variables,
  });
};

const queryGetGame = (id: string): Promise<{ data: GetGameQuery }> => {
  const variables: GetGameQueryVariables = {
    id,
  };
  return client.query({
    query: gql(getGame),
    variables,
  });
};

const subscribeUpdatedGame = (
  gameId: string,
  next: (data: { data: UpdatedGameSubscription }) => void,
  error: (error: object) => void,
): void => {
  const variables: UpdatedGameSubscriptionVariables = {
    id: gameId,
  };
  client
    .subscribe({
      query: gql(updatedGame),
      variables,
    })
    .subscribe({
      next,
      error,
    });
};

const queryGetMessages = (gameId: string): Promise<{ data: GetMessagesQuery }> => {
  const variables: GetMessagesQueryVariables = {
    gameId,
  };
  return client.query({
    query: gql(getMessages),
    variables,
  });
};

const subscribeNewMessage = (
  gameId: string,
  next: (data: { data: NewMessageSubscription }) => void,
  error: (error: object) => void,
): void => {
  const variables: NewMessageSubscriptionVariables = {
    gameId,
  };
  client
    .subscribe({
      query: gql(newMessage),
      variables,
    })
    .subscribe({
      next,
      error,
    });
};

const mutateCreateMessage = (
  gameId: string,
  to: string,
  from: string,
  message: string,
): Promise<{ data: CreateMessageMutation }> => {
  const variables: CreateMessageMutationVariables = {
    gameId,
    to,
    from,
    message,
  };
  return client.mutate({
    mutation: gql(createMessage),
    variables,
  });
};

const AppSyncClient = {
  mutateAddPlayer,
  mutateAddTurn,
  mutateCreateGame,
  mutateCreateMessage,
  mutateStartGame,
  queryGetGame,
  queryGetMessages,
  subscribeNewMessage,
  subscribeUpdatedGame,
};

export { AppSyncClient };
