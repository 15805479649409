import React from 'react';
import { Typography } from '@rmwc/typography';

const Logo: React.FC = () => {
  return (
    <>
      <Typography use="headline3">Airlock</Typography>
      <span className="logo-beta">
        <Typography use="overline">beta</Typography>
      </span>
    </>
  );
};

export { Logo };
