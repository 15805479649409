import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AppSyncClient } from '../../util/appsync';
import { Logo } from '../../components/Logo';
import queryString from 'query-string';

import { Button } from '@rmwc/button';
import { Card, CardActions, CardActionIcons } from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';

const AddPlayer: React.FC<{ location: { search: string } }> = props => {
  const [playerNameInput, setPlayerNameInput] = useState<string | undefined>(undefined);
  const [playerId, setPlayerId] = useState<string | undefined>(undefined);

  const { location } = props;
  const values = queryString.parse(location.search);
  const { gameId } = values;

  const doAddPlayer = (): void => {
    console.log(`adding ${playerNameInput}`);
    AppSyncClient.mutateAddPlayer(gameId as string, playerNameInput as string).then(response => {
      console.log(response.data.addPlayer);
      const players = response.data.addPlayer.players;
      const playerId = players[players.length - 1].id;
      setPlayerId(playerId);
    });
  };

  if (playerId) {
    return <Redirect to={`/play?gameId=${gameId}&playerId=${playerId}`} />;
  }

  return (
    <div className="config-panel">
      <div className="logo spaced">
        <Logo />
      </div>
      <Card>
        <Typography use="overline">What is your name?</Typography>
        <TextField
          label="Name"
          value={playerNameInput}
          onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => {
            event.which === 13 && doAddPlayer();
          }}
          onChange={(event: React.FormEvent<HTMLInputElement>): void => {
            console.log(event);
            setPlayerNameInput(event.currentTarget.value);
          }}
        />
        <CardActions>
          <CardActionIcons>
            <Button raised onClick={doAddPlayer}>
              Ok
            </Button>
          </CardActionIcons>
        </CardActions>
      </Card>
    </div>
  );
};

export { AddPlayer };
