// tslint:disable
// this is an auto generated file. This will be overwritten

export const getGame = `query GetGame($id: ID!) {
  getGame(id: $id) {
    id
    version
    turn
    created
    lastUpdated
    players {
      id
      name
      alias
      state {
        team
        room
        living
        ready
        nextTurn {
          actionType
          nextRoom
          hitList
          airlockList
        }
        turnSummary
      }
    }
    rooms {
      room
      damage
      lastScanDamage
    }
    shipHealth
    scansRemaining
    lastScanTurn
    gameStatus
    turnSummary
    config {
      minPlayers
      numTraitors
      numCrew
      numTurns
      numScans
      numRooms
      maxHealth
      startingDamage
    }
  }
}
`;
export const getMessages = `query GetMessages($gameId: ID!, $nextToken: String) {
  getMessages(gameId: $gameId, nextToken: $nextToken) {
    messages {
      gameId
      to
      from
      created
      message
    }
    nextToken
  }
}
`;
