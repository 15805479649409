import React from 'react';

import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';

const Dead: React.FC<{ airlocked: boolean }> = props => {
  const airlocked = props.airlocked;

  return (
    <div className={`centered player-dead`}>
      <div className="logo spaced">
        <Typography use="headline3">{airlocked ? 'You got airlocked.' : 'You got jumped'}</Typography>
      </div>
      {airlocked && (
        <div className="player-dead-character dead-animate">
          <Icon icon="emoji_people" />
        </div>
      )}
      {!airlocked && (
        <div className="player-dead-character">
          <Icon icon="face" className="head" />
          <Icon icon="build" className="wrench" />
        </div>
      )}
    </div>
  );
};

export { Dead };
