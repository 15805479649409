/* tslint:disable */
//  This file was automatically generated and should not be edited.

export type TurnActionInput = {
  actionType: ActionType;
  nextRoom?: Room | null;
  hitList: Array<string>;
  airlockList: Array<string>;
};

export enum ActionType {
  UNDECIDED = 'UNDECIDED',
  MOVE = 'MOVE',
  REPAIR = 'REPAIR',
  SABOTAGE = 'SABOTAGE',
  SCAN = 'SCAN',
}

export enum Room {
  BRIDGE = 'BRIDGE',
  ENGINE = 'ENGINE',
  MEDICAL = 'MEDICAL',
  STORAGE = 'STORAGE',
  BRIG = 'BRIG',
  HEAD = 'HEAD',
  QUARTERS = 'QUARTERS',
  CANTEEN = 'CANTEEN',
  MAINTENANCE = 'MAINTENANCE',
  RECREATION = 'RECREATION',
  SPACE = 'SPACE',
}

export enum Team {
  CREW = 'CREW',
  TRAITOR = 'TRAITOR',
}

export enum GameStatus {
  PLAYING = 'PLAYING',
  CREW_WON = 'CREW_WON',
  TRAITORS_WON = 'TRAITORS_WON',
}

export type GameInput = {
  id: string;
  version: number;
  turn?: number | null;
  players?: Array<PlayerInput> | null;
  rooms?: Array<RoomStateInput> | null;
  shipHealth?: number | null;
  scansRemaining?: number | null;
  lastScanTurn?: number | null;
  gameStatus?: GameStatus | null;
  turnSummary: Array<string>;
};

export type PlayerInput = {
  name: string;
  alias: string;
  state: PlayerStateInput;
};

export type PlayerStateInput = {
  room: Room;
  living: boolean;
  ready: boolean;
  nextTurn: TurnActionInput;
  turnSummary: Array<string>;
};

export type RoomStateInput = {
  room: Room;
  damage?: number | null;
  lastScanDamage?: number | null;
};

export type AddTurnMutationVariables = {
  gameId: string;
  playerId: string;
  ready: boolean;
  action?: TurnActionInput | null;
};

export type AddTurnMutation = {
  addTurn: {
    __typename: 'Game';
    id: string;
    version: number;
    turn: number;
    created: string;
    lastUpdated: string;
    players: Array<{
      __typename: 'Player';
      id: string;
      name: string;
      alias: string;
      state: {
        __typename: 'PlayerState';
        team: Team;
        room: Room;
        living: boolean;
        ready: boolean;
        nextTurn: {
          __typename: 'TurnAction';
          actionType: ActionType;
          nextRoom: Room | null;
          hitList: Array<string>;
          airlockList: Array<string>;
        };
        turnSummary: Array<string>;
      };
    }>;
    rooms: Array<{
      __typename: 'RoomState';
      room: Room;
      damage: number;
      lastScanDamage: number;
    }>;
    shipHealth: number;
    scansRemaining: number;
    lastScanTurn: number;
    gameStatus: GameStatus;
    turnSummary: Array<string>;
    config: {
      __typename: 'GameConfig';
      minPlayers: number;
      numTraitors: number;
      numCrew: number;
      numTurns: number;
      numScans: number;
      numRooms: number;
      maxHealth: number;
      startingDamage: number;
    };
  };
};

export type StartGameMutationVariables = {
  gameId: string;
};

export type StartGameMutation = {
  startGame: {
    __typename: 'Game';
    id: string;
    version: number;
    turn: number;
    created: string;
    lastUpdated: string;
    players: Array<{
      __typename: 'Player';
      id: string;
      name: string;
      alias: string;
      state: {
        __typename: 'PlayerState';
        team: Team;
        room: Room;
        living: boolean;
        ready: boolean;
        nextTurn: {
          __typename: 'TurnAction';
          actionType: ActionType;
          nextRoom: Room | null;
          hitList: Array<string>;
          airlockList: Array<string>;
        };
        turnSummary: Array<string>;
      };
    }>;
    rooms: Array<{
      __typename: 'RoomState';
      room: Room;
      damage: number;
      lastScanDamage: number;
    }>;
    shipHealth: number;
    scansRemaining: number;
    lastScanTurn: number;
    gameStatus: GameStatus;
    turnSummary: Array<string>;
    config: {
      __typename: 'GameConfig';
      minPlayers: number;
      numTraitors: number;
      numCrew: number;
      numTurns: number;
      numScans: number;
      numRooms: number;
      maxHealth: number;
      startingDamage: number;
    };
  };
};

export type AddPlayerMutationVariables = {
  gameId: string;
  playerName: string;
};

export type AddPlayerMutation = {
  addPlayer: {
    __typename: 'Game';
    id: string;
    version: number;
    turn: number;
    created: string;
    lastUpdated: string;
    players: Array<{
      __typename: 'Player';
      id: string;
      name: string;
      alias: string;
      state: {
        __typename: 'PlayerState';
        team: Team;
        room: Room;
        living: boolean;
        ready: boolean;
        nextTurn: {
          __typename: 'TurnAction';
          actionType: ActionType;
          nextRoom: Room | null;
          hitList: Array<string>;
          airlockList: Array<string>;
        };
        turnSummary: Array<string>;
      };
    }>;
    rooms: Array<{
      __typename: 'RoomState';
      room: Room;
      damage: number;
      lastScanDamage: number;
    }>;
    shipHealth: number;
    scansRemaining: number;
    lastScanTurn: number;
    gameStatus: GameStatus;
    turnSummary: Array<string>;
    config: {
      __typename: 'GameConfig';
      minPlayers: number;
      numTraitors: number;
      numCrew: number;
      numTurns: number;
      numScans: number;
      numRooms: number;
      maxHealth: number;
      startingDamage: number;
    };
  };
};

export type UpdateGameMutationVariables = {
  game: GameInput;
};

export type UpdateGameMutation = {
  updateGame: {
    __typename: 'Game';
    id: string;
    version: number;
    turn: number;
    created: string;
    lastUpdated: string;
    players: Array<{
      __typename: 'Player';
      id: string;
      name: string;
      alias: string;
      state: {
        __typename: 'PlayerState';
        team: Team;
        room: Room;
        living: boolean;
        ready: boolean;
        nextTurn: {
          __typename: 'TurnAction';
          actionType: ActionType;
          nextRoom: Room | null;
          hitList: Array<string>;
          airlockList: Array<string>;
        };
        turnSummary: Array<string>;
      };
    }>;
    rooms: Array<{
      __typename: 'RoomState';
      room: Room;
      damage: number;
      lastScanDamage: number;
    }>;
    shipHealth: number;
    scansRemaining: number;
    lastScanTurn: number;
    gameStatus: GameStatus;
    turnSummary: Array<string>;
    config: {
      __typename: 'GameConfig';
      minPlayers: number;
      numTraitors: number;
      numCrew: number;
      numTurns: number;
      numScans: number;
      numRooms: number;
      maxHealth: number;
      startingDamage: number;
    };
  };
};

export type CreateGameMutationVariables = {
  minPlayers: number;
  numCrew: number;
  numTraitors: number;
  maxHealth: number;
  startingDamage: number;
  numTurns: number;
  numScans: number;
  numRooms: number;
};

export type CreateGameMutation = {
  createGame: {
    __typename: 'Game';
    id: string;
    version: number;
    turn: number;
    created: string;
    lastUpdated: string;
    players: Array<{
      __typename: 'Player';
      id: string;
      name: string;
      alias: string;
      state: {
        __typename: 'PlayerState';
        team: Team;
        room: Room;
        living: boolean;
        ready: boolean;
        nextTurn: {
          __typename: 'TurnAction';
          actionType: ActionType;
          nextRoom: Room | null;
          hitList: Array<string>;
          airlockList: Array<string>;
        };
        turnSummary: Array<string>;
      };
    }>;
    rooms: Array<{
      __typename: 'RoomState';
      room: Room;
      damage: number;
      lastScanDamage: number;
    }>;
    shipHealth: number;
    scansRemaining: number;
    lastScanTurn: number;
    gameStatus: GameStatus;
    turnSummary: Array<string>;
    config: {
      __typename: 'GameConfig';
      minPlayers: number;
      numTraitors: number;
      numCrew: number;
      numTurns: number;
      numScans: number;
      numRooms: number;
      maxHealth: number;
      startingDamage: number;
    };
  };
};

export type CreateMessageMutationVariables = {
  gameId: string;
  to: string;
  from: string;
  message: string;
};

export type CreateMessageMutation = {
  createMessage: {
    __typename: 'Message';
    gameId: string;
    to: string;
    from: string;
    created: string;
    message: string;
  };
};

export type GetGameQueryVariables = {
  id: string;
};

export type GetGameQuery = {
  getGame: {
    __typename: 'Game';
    id: string;
    version: number;
    turn: number;
    created: string;
    lastUpdated: string;
    players: Array<{
      __typename: 'Player';
      id: string;
      name: string;
      alias: string;
      state: {
        __typename: 'PlayerState';
        team: Team;
        room: Room;
        living: boolean;
        ready: boolean;
        nextTurn: {
          __typename: 'TurnAction';
          actionType: ActionType;
          nextRoom: Room | null;
          hitList: Array<string>;
          airlockList: Array<string>;
        };
        turnSummary: Array<string>;
      };
    }>;
    rooms: Array<{
      __typename: 'RoomState';
      room: Room;
      damage: number;
      lastScanDamage: number;
    }>;
    shipHealth: number;
    scansRemaining: number;
    lastScanTurn: number;
    gameStatus: GameStatus;
    turnSummary: Array<string>;
    config: {
      __typename: 'GameConfig';
      minPlayers: number;
      numTraitors: number;
      numCrew: number;
      numTurns: number;
      numScans: number;
      numRooms: number;
      maxHealth: number;
      startingDamage: number;
    };
  };
};

export type GetMessagesQueryVariables = {
  gameId: string;
  nextToken?: string | null;
};

export type GetMessagesQuery = {
  getMessages: {
    __typename: 'PaginatedMessages';
    messages: Array<{
      __typename: 'Message';
      gameId: string;
      to: string;
      from: string;
      created: string;
      message: string;
    }>;
    nextToken: string | null;
  };
};

export type UpdatedGameSubscriptionVariables = {
  id: string;
};

export type UpdatedGameSubscription = {
  updatedGame: {
    __typename: 'Game';
    id: string;
    version: number;
    turn: number;
    created: string;
    lastUpdated: string;
    players: Array<{
      __typename: 'Player';
      id: string;
      name: string;
      alias: string;
      state: {
        __typename: 'PlayerState';
        team: Team;
        room: Room;
        living: boolean;
        ready: boolean;
        nextTurn: {
          __typename: 'TurnAction';
          actionType: ActionType;
          nextRoom: Room | null;
          hitList: Array<string>;
          airlockList: Array<string>;
        };
        turnSummary: Array<string>;
      };
    }>;
    rooms: Array<{
      __typename: 'RoomState';
      room: Room;
      damage: number;
      lastScanDamage: number;
    }>;
    shipHealth: number;
    scansRemaining: number;
    lastScanTurn: number;
    gameStatus: GameStatus;
    turnSummary: Array<string>;
    config: {
      __typename: 'GameConfig';
      minPlayers: number;
      numTraitors: number;
      numCrew: number;
      numTurns: number;
      numScans: number;
      numRooms: number;
      maxHealth: number;
      startingDamage: number;
    };
  } | null;
};

export type NewMessageSubscriptionVariables = {
  gameId: string;
};

export type NewMessageSubscription = {
  newMessage: {
    __typename: 'Message';
    gameId: string;
    to: string;
    from: string;
    created: string;
    message: string;
  };
};
