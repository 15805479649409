import React, { useEffect, useState } from 'react';
import { Dead } from '../Dead';
import { PreGame } from '../PreGame';
import { PlayGameBar } from '../PlayGameBar';
import { Rules } from '../Rules';
import { MessagesView } from '../MessagesView';
import { AppSyncClient } from '../../util/appsync';
import { EnumHelper } from '../../util/enumhelper';
import { Button } from '@rmwc/button';
import { Card, CardActions, CardActionIcons } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { Chip } from '@rmwc/chip';
import { Checkbox } from '@rmwc/checkbox';
import {
  DataTable,
  DataTableContent,
  DataTableHead,
  DataTableRow,
  DataTableHeadCell,
  DataTableBody,
  DataTableCell,
} from '@rmwc/data-table';
import { ListDivider } from '@rmwc/list';
import { TabBar, Tab } from '@rmwc/tabs';
import { Typography } from '@rmwc/typography';
import { Radio } from '@rmwc/radio';
import { Select } from '@rmwc/select';
import { SnackbarQueue, createSnackbarQueue } from '@rmwc/snackbar';
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogButton } from '@rmwc/dialog';
import queryString from 'query-string';
import { GameStatus, GetGameQuery, GetMessagesQuery, Team, Room, ActionType } from '../../API';

type Game = GetGameQuery['getGame'];
type Player = GetGameQuery['getGame']['players'][0];
type Message = GetMessagesQuery['getMessages']['messages'][0];

interface Props {
  location: {
    search: string;
  };
}

const snackbarQueue = createSnackbarQueue();

const showError = (errorType: string) => (error: object): void => {
  console.error(JSON.stringify(error));
  snackbarQueue.notify({
    title: <Typography use="caption">There was an error, you may need to reload the page:</Typography>,
    body: errorType,
    icon: 'error',
    dismissIcon: true,
    stacked: false,
  });
};

const TurnSummary: React.FC<{
  open: boolean;
  setTurnSummaryOpen: (open: boolean) => void;
  gameSummary: string[];
  playerSummary: string[];
}> = props => {
  const { open, setTurnSummaryOpen, gameSummary, playerSummary } = props;
  return (
    <Dialog
      open={open}
      onClose={(evt): void => {
        console.log('closing turn summary');
        setTurnSummaryOpen(false);
      }}
    >
      <DialogTitle>Turn summary</DialogTitle>
      <DialogContent>
        {gameSummary && gameSummary.map(item => <div key={item}>{item}</div>)}
        <ListDivider />
        {playerSummary && playerSummary.map(item => <div key={item}>{item}</div>)}
      </DialogContent>
      <DialogActions>
        <DialogButton raised action="close">
          Ok
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

const RulesDialog: React.FC<{ open: boolean; setRulesOpen: (open: boolean) => void }> = props => {
  const { open, setRulesOpen } = props;
  return (
    <div className="rules-dialog">
      <Dialog
        open={open}
        onClose={(evt): void => {
          setRulesOpen(false);
        }}
      >
        <DialogTitle>Rules</DialogTitle>
        <DialogContent>
          <Rules />
        </DialogContent>
        <DialogActions>
          <a
            title="support@moonfetus.com"
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:support@moonfetus.com"
          >
            Send feedback
          </a>
          <DialogButton raised isDefaultAction action="close">
            Close
          </DialogButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const Profile: React.FC<{ player: Player; open: boolean; setProfileOpen: (open: boolean) => void }> = props => {
  const { player, open, setProfileOpen } = props;
  return (
    <Dialog
      open={open}
      onClose={(evt): void => {
        console.log(evt.detail.action);
        setProfileOpen(false);
      }}
    >
      <DialogTitle>{player.name}</DialogTitle>
      <DialogContent>
        <ListDivider />
        <div>Team: {EnumHelper.friendlyTeamName(player.state.team)}</div>
      </DialogContent>
      <DialogActions>
        <DialogButton raised action="close">
          Close
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
};

const PlayGame: React.FC<Props> = props => {
  const { location } = props;
  const values = queryString.parse(location.search);
  const gameId: string = values.gameId as string;
  const playerId: string = values.playerId as string;

  const [game, setGame] = useState<Game | undefined>(undefined);
  const [messages, setMessages] = useState<Message[]>([]);
  const [snackbarMessages, setSnackbarMessages] = useState<Set<Message>>(new Set());
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [turnSummaryOpen, setTurnSummaryOpen] = React.useState(false);
  const [rulesOpen, setRulesOpen] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState(0);

  const [turnAction, setActionType] = React.useState<ActionType>(ActionType.UNDECIDED);
  const [nextRoom, setNextRoom] = React.useState<Room | undefined>(undefined);

  const [jumpList, setJumpList] = React.useState<Set<string>>(new Set());
  const [airlockList, setAirlockList] = React.useState<Set<string>>(new Set());

  // keeping this state up here so we can preserve it and use it for snackbar logic
  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('all');
  const [messageDraft, setMessageDraft] = useState<string>('');

  useEffect(() => {
    // warn player before leaving the page
    window.addEventListener('beforeunload', event => {
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    });

    // save game info to localstorage so player can rejoin (expiration is 30 minutes)
    const expiration = new Date().getTime() / 1000 + 30 * 60;
    window.localStorage.setItem('vacuum.currentGameInfo', `${gameId}:${playerId}:${expiration}`);
  }, [gameId, playerId]);

  useEffect(() => {
    const getAndSubscribeGame = (): void => {
      AppSyncClient.queryGetGame(gameId)
        .then(response => {
          console.log(response);
          setGame(response.data.getGame);

          // FIXME: should we be unsubscribing somewhere?
          AppSyncClient.subscribeUpdatedGame(
            gameId,
            response => {
              console.log(response);
              const newGame: Game = response.data.updatedGame as Game;
              setGame(prev => {
                // if this is a new turn, show the turn summary
                if (newGame.gameStatus === GameStatus.PLAYING && prev && prev.turn < newGame.turn && newGame.turn > 1) {
                  setTurnSummaryOpen(true);
                }
                return newGame;
              });
            },
            showError('Error while updating game data'),
          );
        })
        .catch(showError('Error while loading game'));
    };

    const getAndSubscribeMessages = (): void => {
      AppSyncClient.queryGetMessages(gameId)
        .then(response => {
          console.log(response);
          setMessages(response.data.getMessages.messages);

          // FIXME: should we be unsubscribing somewhere?
          AppSyncClient.subscribeNewMessage(
            gameId,
            response => {
              setMessages(prev => {
                prev.push(response.data.newMessage);
                return Array.from(prev);
              });

              // see note later, this is kind of a goofy way of doing what we need to do
              setSnackbarMessages(prev => new Set(prev.add(response.data.newMessage)));
            },
            showError('Error while updating message data'),
          );
        })
        .catch(showError('Error while loading messages'));
    };

    getAndSubscribeGame();
    getAndSubscribeMessages();
  }, [gameId]);

  if (!game) {
    return <CircularProgress />;
  }

  const player = game.players.find(player => {
    return player.id === playerId;
  });
  if (!player) {
    return <h1>player not found</h1>;
  }

  if (game.turn === 0) {
    return <PreGame game={game} />;
  } else if (!player.state.living) {
    return <Dead airlocked={player.state.room === Room.SPACE} />;
  } else {
    // show snackbars
    // FIXME: this is gross, we do this so we can use the game state to show the snackbar, maybe there is a cleaner way to do this
    if (snackbarMessages.size > 0) {
      snackbarMessages.forEach(message => {
        setSnackbarMessages(prev => {
          prev.delete(message);
          const fromPlayer = game.players.find(thisPlayer => thisPlayer.id === message.from);
          if (message.to === playerId && selectedPlayerId !== message.from) {
            setTimeout(() =>
              snackbarQueue.notify({
                title: <Typography use="caption">{fromPlayer && fromPlayer.name}:</Typography>,
                body: <>{message.message}</>,
                icon: 'message',
                dismissIcon: true,
                stacked: false,
              }),
            );
          }
          return new Set(prev);
        });
      });
    }

    const room = game.rooms.find(room => {
      return room.room === player.state.room;
    });
    if (!room) {
      return <h1>room not found</h1>;
    }

    const readyChanged = (isReady: boolean): void => {
      AppSyncClient.mutateAddTurn(
        game.id,
        player.id,
        isReady,
        turnAction,
        nextRoom,
        Array.from(jumpList.values()),
        Array.from(airlockList.values()),
      ).catch(showError('Error while setting ready status'));
    };

    const BridgeView: React.FC = () => {
      return (
        <DataTable>
          <DataTableContent>
            <DataTableHead>
              <DataTableRow>
                <DataTableHeadCell>Room</DataTableHeadCell>
                <DataTableHeadCell>Damage</DataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {game.rooms.map(room => {
                return (
                  <DataTableRow key={room.room}>
                    <DataTableCell>{EnumHelper.friendlyRoomName(room.room)}</DataTableCell>
                    <DataTableCell>{room.lastScanDamage}</DataTableCell>
                  </DataTableRow>
                );
              })}
            </DataTableBody>
          </DataTableContent>
        </DataTable>
      );
    };

    const roomPlayers = game.players.filter(player => player.state.room === room.room);

    const RoomPlayers: React.FC = () => {
      return (
        <div>
          {roomPlayers.map(player => (
            <Chip key={player.id}>
              {!player.state.living && '💀 '}
              {player.name}
            </Chip>
          ))}
        </div>
      );
    };

    const SabotageRadio: React.FC = () => {
      // can only sabotage if there are no living crew in the room
      if (!roomPlayers.find(roomPlayer => roomPlayer.state.team === Team.CREW && roomPlayer.state.living)) {
        return (
          <Radio
            checked={turnAction === ActionType.SABOTAGE}
            onChange={(event): void => event.currentTarget.value && setActionType(ActionType.SABOTAGE)}
          >
            Sabotage
          </Radio>
        );
      } else {
        return <Radio disabled={true}>Can't sabotage, not alone</Radio>;
      }
    };

    const changeNextAction = (action: ActionType): void => {
      setActionType(action);
      readyChanged(false);
    };

    const TurnView: React.FC = () => {
      return (
        <div className="config-panel">
          <br />
          <Card>
            <div>
              <Typography use="headline6">Location: {EnumHelper.friendlyRoomName(player.state.room)}</Typography>
            </div>
            <div>
              <Typography use="subtitle2">{room.damage} damage</Typography>
            </div>
            <ListDivider />
            <RoomPlayers />
          </Card>

          <br />

          {player.state.room === Room.BRIDGE && (
            <>
              <Card>
                <div>
                  <Typography use="headline6">Scan results</Typography>
                </div>
                <div>
                  <Typography use="subtitle2">Last scanned on turn {game.lastScanTurn}</Typography>
                </div>
                <ListDivider />
                <BridgeView />
              </Card>
              <br />
            </>
          )}
          <Card>
            <Typography use="headline6">Next action</Typography>
            <ListDivider />
            <div>
              <Radio
                checked={turnAction === ActionType.UNDECIDED}
                onChange={(event): void => changeNextAction(ActionType.UNDECIDED)}
              >
                Do nothing
              </Radio>
            </div>
            <div>
              <Radio
                checked={turnAction === ActionType.MOVE}
                onChange={(event): void => changeNextAction(ActionType.MOVE)}
              >
                <Select
                  value={nextRoom}
                  label="Move to"
                  onChange={(event): void => {
                    setNextRoom(event.currentTarget.value);
                    changeNextAction(ActionType.MOVE);
                  }}
                  options={game.rooms.map(thisRoom => {
                    return {
                      label: EnumHelper.friendlyRoomName(thisRoom.room),
                      value: thisRoom.room,
                    };
                  })}
                />
              </Radio>
            </div>
            <div>
              {room.damage > 0 && (
                <Radio
                  checked={turnAction === ActionType.REPAIR}
                  onChange={(event): void => event.currentTarget.value && changeNextAction(ActionType.REPAIR)}
                >
                  Repair
                </Radio>
              )}
              {player.state.team === Team.TRAITOR && <div><SabotageRadio /></div>}
            </div>
            {room.room === Room.BRIDGE && (
              <div>
                {game.scansRemaining > 0 && (
                  <Radio
                    checked={turnAction === ActionType.SCAN}
                    onChange={(event): void => event.currentTarget.value && changeNextAction(ActionType.SCAN)}
                  >
                    Scan ({game.scansRemaining} remaining)
                  </Radio>
                )}
                {game.scansRemaining < 1 && <Radio disabled={true}>No scans remaining</Radio>}
              </div>
            )}
          </Card>
          <br />
        </div>
      );
    };

    const getVisibleTeam = (thisPlayer: Player): string => {
      return thisPlayer.id === playerId || !thisPlayer.state.living || player.state.team === Team.TRAITOR
        ? thisPlayer.state.team
        : '?';
    };

    const PlayerView: React.FC = () => {
      return (
        <div className="config-panel">
          <br />
          <DataTable>
            <DataTableContent>
              <DataTableHead>
                <DataTableRow>
                  <DataTableHeadCell>Name</DataTableHeadCell>
                  <DataTableHeadCell>Team</DataTableHeadCell>
                  <DataTableHeadCell>Airlock</DataTableHeadCell>
                  {player.state.team === Team.TRAITOR && <DataTableHeadCell>Jump</DataTableHeadCell>}
                </DataTableRow>
              </DataTableHead>
              <DataTableBody>
                {game.players
                  //.filter(thisPlayer => thisPlayer.id !== player.id)
                  .map(thisPlayer => {
                    return (
                      <DataTableRow key={thisPlayer.id} activated={thisPlayer.id === player.id}>
                        <DataTableCell>
                          {thisPlayer.state.living || <>💀 </>}
                          {thisPlayer.state.ready && <>✅ </>}
                          {thisPlayer.name}
                          {thisPlayer.id === player.id && ' (you)'}
                        </DataTableCell>
                        <DataTableCell>{getVisibleTeam(thisPlayer)}</DataTableCell>
                        <DataTableCell alignEnd>
                          <Checkbox
                            disabled={!thisPlayer.state.living || thisPlayer.id === player.id}
                            checked={airlockList.has(thisPlayer.id)}
                            onChange={(event): void => {
                              readyChanged(false);
                              if (event.currentTarget.checked) {
                                setAirlockList(prev => {
                                  prev.add(thisPlayer.id);
                                  return new Set(prev);
                                });
                              } else {
                                setAirlockList(prev => {
                                  prev.delete(thisPlayer.id);
                                  return new Set(prev);
                                });
                              }
                            }}
                          />
                        </DataTableCell>
                        {player.state.team === Team.TRAITOR && (
                          <DataTableCell alignEnd>
                            <Checkbox
                              disabled={!thisPlayer.state.living || thisPlayer.id === player.id}
                              checked={jumpList.has(thisPlayer.id)}
                              onChange={(event): void => {
                                readyChanged(false);
                                if (event.currentTarget.checked) {
                                  setJumpList(prev => {
                                    prev.add(thisPlayer.id);
                                    return new Set(prev);
                                  });
                                } else {
                                  setJumpList(prev => {
                                    prev.delete(thisPlayer.id);
                                    return new Set(prev);
                                  });
                                }
                              }}
                            />
                          </DataTableCell>
                        )}
                      </DataTableRow>
                    );
                  })}
              </DataTableBody>
            </DataTableContent>
          </DataTable>
        </div>
      );
    };

    if (game.gameStatus !== GameStatus.PLAYING) {
      return (
        <div className="config-panel">
          <div className="logo spaced">
            <Typography use="headline3">{EnumHelper.friendlyGameStatus(game.gameStatus)}!</Typography>
          </div>
          <Card>
            <div>
              {game.turnSummary && game.turnSummary.map(item => <div key={item}>{item}</div>)}
              <ListDivider />
              {player.state.turnSummary && player.state.turnSummary.map(item => <div key={item}>{item}</div>)}
            </div>
            <CardActions>
              <CardActionIcons>
                <Button
                  raised
                  onClick={(): void => {
                    // restart game
                    AppSyncClient.mutateStartGame(game.id).then(response => {
                      console.log(response);
                    });
                  }}
                >
                  Play again!
                </Button>
                <Button
                  raised
                  onClick={(): void => {
                    // clear previous game info so resume button isn't shown on create page
                    window.localStorage.removeItem('vacuum.currentGameInfo');
                    window.location.href = '/';
                  }}
                >
                  New Game!
                </Button>
              </CardActionIcons>
            </CardActions>
          </Card>
        </div>
      );
    } else {
      return (
        <div className="light-bg">
          <Profile player={player} open={profileOpen} setProfileOpen={setProfileOpen} />
          <TurnSummary
            open={turnSummaryOpen}
            setTurnSummaryOpen={setTurnSummaryOpen}
            gameSummary={game.turnSummary}
            playerSummary={player.state.turnSummary}
          />
          <RulesDialog open={rulesOpen} setRulesOpen={setRulesOpen} />
          <SnackbarQueue messages={snackbarQueue.messages} stacked />
          <div className="bar-space">
            <PlayGameBar
              game={game}
              player={player}
              setProfileOpen={setProfileOpen}
              setRulesOpen={setRulesOpen}
              readyChanged={readyChanged}
            />
            <TabBar activeTabIndex={activeTab} onActivate={(event): void => setActiveTab(event.detail.index)}>
              <Tab restrictIndicator>Turn</Tab>
              <Tab restrictIndicator>Players</Tab>
              <Tab restrictIndicator>Messages</Tab>
            </TabBar>
          </div>
          <div className="content-space">
            {activeTab === 0 && <TurnView />}
            {activeTab === 1 && <PlayerView />}
            {activeTab === 2 && (
              <MessagesView
                gameId={game.id}
                playerId={player.id}
                players={game.players}
                messages={messages}
                selectedPlayerId={selectedPlayerId}
                setSelectedPlayerId={setSelectedPlayerId}
                messageDraft={messageDraft}
                setMessageDraft={setMessageDraft}
              />
            )}
          </div>
        </div>
      );
    }
  }
};

export { PlayGame };
