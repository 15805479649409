import React from 'react';
import { Checkbox } from '@rmwc/checkbox';
import { Chip } from '@rmwc/chip';
import { LinearProgress } from '@rmwc/linear-progress';
import { TopAppBar, TopAppBarActionItem, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from '@rmwc/top-app-bar';
import { Typography } from '@rmwc/typography';

import { GetGameQuery, Team } from '../../API';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/chips/dist/mdc.chips.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/linear-progress/dist/mdc.linear-progress.css';
import '@material/top-app-bar/dist/mdc.top-app-bar.css';
import '@material/typography/dist/mdc.typography.css';

type Game = GetGameQuery['getGame'];
type Player = Game['players'][0];

const PlayGameBar: React.FC<{
  game: Game;
  player: Player;
  setProfileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRulesOpen: React.Dispatch<React.SetStateAction<boolean>>;
  readyChanged: (isReady: boolean) => void;
}> = props => {
  const { game, player, setProfileOpen, setRulesOpen, readyChanged } = props;

  const getPlayerCount = (team: Team, alive: boolean): number => {
    return game.players.filter(player => player.state.living === alive && player.state.team === team).length;
  };

  if (!game) {
    return <></>;
  }

  const numCrew: number = getPlayerCount(Team.CREW, true);
  const numTraitors: number = getPlayerCount(Team.TRAITOR, true);
  const turnProgress = game.turn / game.config.numTurns;

  return (
    <div>
      <TopAppBar fixed>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <TopAppBarTitle>
              <Chip label={`🚀 ${game.shipHealth}`} />
            </TopAppBarTitle>
            <TopAppBarTitle>
              <span className="crew-count">
                <Chip label={`${numCrew}`} />
              </span>
            </TopAppBarTitle>
            <TopAppBarTitle>
              <span className="traitor-count">
                <Chip label={`${numTraitors}`} />
              </span>
            </TopAppBarTitle>
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <TopAppBarActionItem onClick={(): void => setProfileOpen(true)} icon="emoji_people" />
            <TopAppBarActionItem onClick={(): void => setRulesOpen(true)} icon="help" />
            <Checkbox
              checked={player.state.ready}
              onChange={(event): void => readyChanged(event.currentTarget.checked)}
            />
          </TopAppBarSection>
        </TopAppBarRow>
        <TopAppBarRow>
          <TopAppBarSection>
            <span className="turn-count">
              <Typography use="caption">
                {game.turn}/{game.config.numTurns}
              </Typography>
            </span>
            <LinearProgress progress={turnProgress} />
            <TopAppBarActionItem icon="home" />
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
    </div>
  );
};

export { PlayGameBar };
