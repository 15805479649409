// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_appsync_graphqlEndpoint": "https://jiujhftp3fdy3c6kehhncv2l34.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-tklhkonscff7dpj3kpqcwjzmka",
};

export default awsmobile;
