import { GameStatus, Team, Room } from '../../API';

const friendlyRoomName = (room: Room): string => {
  switch (room) {
    case Room.BRIDGE:
      return 'The Bridge';
    case Room.ENGINE:
      return 'The Engine Room';
    case Room.MEDICAL:
      return 'Medical';
    case Room.STORAGE:
      return 'Storage';
    case Room.BRIG:
      return 'The Brig';
    case Room.HEAD:
      return 'The Head';
    case Room.QUARTERS:
      return 'Quarters';
    case Room.CANTEEN:
      return 'The Canteen';
    case Room.MAINTENANCE:
      return 'Maintenance';
    case Room.RECREATION:
      return 'The Rec Room';
    case Room.SPACE:
      return 'Cold, dark space';
    default:
      return room;
  }
};

const friendlyTeamName = (team: Team): string => {
  switch (team) {
    case Team.CREW:
      return 'The Crew';
    case Team.TRAITOR:
      return 'Traitors';
    default:
      return team;
  }
};

const friendlyGameStatus = (gameStatus: GameStatus): string => {
  switch (gameStatus) {
    case GameStatus.CREW_WON:
      return 'The Crew won';
    case GameStatus.TRAITORS_WON:
      return 'Traitors won';
    default:
      return gameStatus;
  }
};

export const EnumHelper = {
  friendlyRoomName,
  friendlyTeamName,
  friendlyGameStatus,
};
