import React from 'react';

import { ListDivider } from '@rmwc/list';

const Rules: React.FC = () => {
  return (
    <>
      <div>There are 2 teams: crew and traitors</div>
      <ListDivider />
      <div>The crew wins if the ship gets home</div>
      <ListDivider />
      <div>Traitors win if all crew die or the ship explodes</div>
      <ListDivider />
      <div>The ship gets 1 closer to home each turn</div>
      <ListDivider />
      <div>Damaged rooms subtract from the ship health each turn</div>
      <ListDivider />
      <div>If the ship gets to 0 health it explodes</div>
      <ListDivider />
      <div>The crew can repair rooms and traitors can sabotage (damage) rooms</div>
      <ListDivider />
      <div>Players can airlock (kill) another player by majority vote</div>
      <ListDivider />
      <div>Traitors can can jump (kill) a player if they are alone in a room before a player moves there</div>
      <ListDivider />
      <div>Players can upate their airlock and jump lists at any time</div>
    </>
  );
};

export { Rules };
