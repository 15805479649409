import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Logo } from '../../components/Logo';
import { AppSyncClient } from '../../util/appsync';

const ConfigField: React.FC<{
  invalid: boolean;
  label: string;
  value: string;
  setter: (event: string) => void;
}> = props => {
  const invalid = props.invalid;
  const label = props.label;
  const value = props.value;
  const setter = props.setter;
  return (
    <div className="spaced">
      <TextField
        invalid={invalid}
        label={label}
        value={value}
        onChange={(event: React.FormEvent<HTMLInputElement>): void => {
          setter(event.currentTarget.value);
        }}
      />
    </div>
  );
};

const CreateGame: React.FC<{ location: { search: string } }> = props => {
  const [gameId, setGameId] = useState<string | undefined>(undefined);
  const [numPlayers, setNumPlayers] = useState<string>('6');
  const [numTraitors, setNumTraitors] = useState<string>('2');
  const [maxHealth, setMaxHealth] = useState<string>('100');
  const [startingDamage, setStartingDamage] = useState<string>('12');
  const [numTurns, setNumTurns] = useState<string>('15');
  const [numScans, setNumScans] = useState<string>('4');
  const [numRooms, setNumRooms] = useState<string>('8');

  const [numPlayersValid, setNumPlayersValid] = useState<boolean>(true);
  const [numTraitorsValid, setNumTraitorsValid] = useState<boolean>(true);
  const [maxHealthValid, setMaxHealthValid] = useState<boolean>(true);
  const [startingDamageValid, setStartingDamageValid] = useState<boolean>(true);
  const [numTurnsValid, setNumTurnsValid] = useState<boolean>(true);
  const [numScansValid, setNumScansValid] = useState<boolean>(true);
  const [numRoomsValid, setNumRoomsValid] = useState<boolean>(true);

  const validateInput = ():
    | {
        validMinPlayers: number;
        validNumCrew: number;
        validNumTraitors: number;
        validMaxHealth: number;
        validStartingDamage: number;
        validNumTurns: number;
        validNumScans: number;
        validNumRooms: number;
      }
    | undefined => {
    setNumPlayersValid(true);
    setNumTraitorsValid(true);
    setMaxHealthValid(true);
    setStartingDamageValid(true);
    setNumTurnsValid(true);
    setNumScansValid(true);
    setNumRoomsValid(true);

    const validMinPlayers = parseInt(numPlayers);
    const validNumTraitors = parseInt(numTraitors);
    const validMaxHealth = parseInt(maxHealth);
    const validStartingDamage = parseInt(startingDamage);
    const validNumTurns = parseInt(numTurns);
    const validNumScans = parseInt(numScans);
    const validNumRooms = parseInt(numRooms);

    let allInputValid = true;

    if (!validMinPlayers || validMinPlayers < 1) {
      allInputValid = false;
      setNumPlayersValid(false);
    }
    if (!validNumTraitors || validNumTraitors < 1) {
      allInputValid = false;
      setNumTraitorsValid(false);
    }
    if (!validMaxHealth || validMaxHealth < 1) {
      allInputValid = false;
      setMaxHealthValid(false);
    }
    if (!validStartingDamage || validStartingDamage < 0) {
      allInputValid = false;
      setStartingDamageValid(false);
    }
    if (!validNumTurns || validNumTurns < 1) {
      allInputValid = false;
      setNumTurnsValid(false);
    }
    if (!validNumScans || validNumScans < 0) {
      allInputValid = false;
      setNumScansValid(false);
    }
    if (!validNumRooms || validNumRooms < 2) {
      allInputValid = false;
      setNumRoomsValid(false);
    }

    if (!allInputValid) {
      return undefined;
    } else {
      return {
        validMinPlayers,
        validNumCrew: validMinPlayers - validNumTraitors,
        validNumTraitors,
        validMaxHealth,
        validStartingDamage,
        validNumTurns,
        validNumScans,
        validNumRooms,
      };
    }
  };

  const doCreateGame = (): void => {
    const validInput = validateInput();

    if (validInput) {
      AppSyncClient.mutateCreateGame(
        validInput.validMinPlayers,
        validInput.validNumCrew,
        validInput.validNumTraitors,
        validInput.validMaxHealth,
        validInput.validStartingDamage,
        validInput.validNumTurns,
        validInput.validNumScans,
        validInput.validNumRooms,
      ).then(response => {
        setGameId(response.data.createGame.id);
      });
    }
  };

  const RejoinButton: React.FC = () => {
    const currentGameInfo = window.localStorage.getItem('vacuum.currentGameInfo');
    if (currentGameInfo) {
      const gameInfo = currentGameInfo.split(':');

      console.log(`gameInfo (${gameInfo.length}): ${gameInfo}`);

      // don't show link if game has expired
      if (gameInfo.length > 2) {
        const expiration = parseInt(gameInfo[2]);
        const now = new Date().getTime() / 1000;
        console.log(`now: ${now} v. expiration: ${expiration}`);
        if (now > expiration) {
          return <></>;
        }
      }

      return (
        <>
          <Button
            raised
            onClick={(): void => {
              window.location.href = `play?gameId=${gameInfo[0]}&playerId=${gameInfo[1]}`;
            }}
          >
            Rejoin Current Game
          </Button>
          <hr />
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="config-panel">
      <div className="logo spaced">
        <Logo />
      </div>
      <div className="spaced centered create-buttons">
        <div>
          <RejoinButton />
        </div>
        <div>
          <Button raised onClick={(): void => doCreateGame()}>
            Create New Game
          </Button>
        </div>
      </div>
      <div className="spaced">
        <Card>
          <Typography use="overline">Options</Typography>
          <ConfigField invalid={!numPlayersValid} label="# players" value={numPlayers} setter={setNumPlayers} />
          <ConfigField label="# traitors" invalid={!numTraitorsValid} value={numTraitors} setter={setNumTraitors} />
          <ConfigField label="Ship health" value={maxHealth} invalid={!maxHealthValid} setter={setMaxHealth} />
          <ConfigField
            label="Starting damage"
            value={startingDamage}
            invalid={!startingDamageValid}
            setter={setStartingDamage}
          />
          <ConfigField label="# turns until home" invalid={!numTurnsValid} value={numTurns} setter={setNumTurns} />
          <ConfigField label="# scans" invalid={!numScansValid} value={numScans} setter={setNumScans} />
          <ConfigField label="# rooms" invalid={!numRoomsValid} value={numRooms} setter={setNumRooms} />
        </Card>
      </div>
      <div className="feedback-info">
        Have feedback? <a href="mailto:support@moonfetus.com">support@moonfetus.com</a>
      </div>
      <div className="coil-info">
        <a href="https://coil.com">
          <img alt="coil C" src="coil-c.png" /> Coil
        </a>{' '}
        enabled
      </div>
      {gameId && <Redirect to={`/addPlayer?gameId=${gameId}`} />}
    </div>
  );
};

export { CreateGame };
