// tslint:disable
// this is an auto generated file. This will be overwritten

export const addTurn = `mutation AddTurn(
  $gameId: ID!
  $playerId: String!
  $ready: Boolean!
  $action: TurnActionInput
) {
  addTurn(
    gameId: $gameId
    playerId: $playerId
    ready: $ready
    action: $action
  ) {
    id
    version
    turn
    created
    lastUpdated
    players {
      id
      name
      alias
      state {
        team
        room
        living
        ready
        nextTurn {
          actionType
          nextRoom
          hitList
          airlockList
        }
        turnSummary
      }
    }
    rooms {
      room
      damage
      lastScanDamage
    }
    shipHealth
    scansRemaining
    lastScanTurn
    gameStatus
    turnSummary
    config {
      minPlayers
      numTraitors
      numCrew
      numTurns
      numScans
      numRooms
      maxHealth
      startingDamage
    }
  }
}
`;
export const startGame = `mutation StartGame($gameId: ID!) {
  startGame(gameId: $gameId) {
    id
    version
    turn
    created
    lastUpdated
    players {
      id
      name
      alias
      state {
        team
        room
        living
        ready
        nextTurn {
          actionType
          nextRoom
          hitList
          airlockList
        }
        turnSummary
      }
    }
    rooms {
      room
      damage
      lastScanDamage
    }
    shipHealth
    scansRemaining
    lastScanTurn
    gameStatus
    turnSummary
    config {
      minPlayers
      numTraitors
      numCrew
      numTurns
      numScans
      numRooms
      maxHealth
      startingDamage
    }
  }
}
`;
export const addPlayer = `mutation AddPlayer($gameId: ID!, $playerName: String!) {
  addPlayer(gameId: $gameId, playerName: $playerName) {
    id
    version
    turn
    created
    lastUpdated
    players {
      id
      name
      alias
      state {
        team
        room
        living
        ready
        nextTurn {
          actionType
          nextRoom
          hitList
          airlockList
        }
        turnSummary
      }
    }
    rooms {
      room
      damage
      lastScanDamage
    }
    shipHealth
    scansRemaining
    lastScanTurn
    gameStatus
    turnSummary
    config {
      minPlayers
      numTraitors
      numCrew
      numTurns
      numScans
      numRooms
      maxHealth
      startingDamage
    }
  }
}
`;
export const updateGame = `mutation UpdateGame($game: GameInput!) {
  updateGame(game: $game) {
    id
    version
    turn
    created
    lastUpdated
    players {
      id
      name
      alias
      state {
        team
        room
        living
        ready
        nextTurn {
          actionType
          nextRoom
          hitList
          airlockList
        }
        turnSummary
      }
    }
    rooms {
      room
      damage
      lastScanDamage
    }
    shipHealth
    scansRemaining
    lastScanTurn
    gameStatus
    turnSummary
    config {
      minPlayers
      numTraitors
      numCrew
      numTurns
      numScans
      numRooms
      maxHealth
      startingDamage
    }
  }
}
`;
export const createGame = `mutation CreateGame(
  $minPlayers: Int!
  $numCrew: Int!
  $numTraitors: Int!
  $maxHealth: Int!
  $startingDamage: Int!
  $numTurns: Int!
  $numScans: Int!
  $numRooms: Int!
) {
  createGame(
    minPlayers: $minPlayers
    numCrew: $numCrew
    numTraitors: $numTraitors
    maxHealth: $maxHealth
    startingDamage: $startingDamage
    numTurns: $numTurns
    numScans: $numScans
    numRooms: $numRooms
  ) {
    id
    version
    turn
    created
    lastUpdated
    players {
      id
      name
      alias
      state {
        team
        room
        living
        ready
        nextTurn {
          actionType
          nextRoom
          hitList
          airlockList
        }
        turnSummary
      }
    }
    rooms {
      room
      damage
      lastScanDamage
    }
    shipHealth
    scansRemaining
    lastScanTurn
    gameStatus
    turnSummary
    config {
      minPlayers
      numTraitors
      numCrew
      numTurns
      numScans
      numRooms
      maxHealth
      startingDamage
    }
  }
}
`;
export const createMessage = `mutation CreateMessage($gameId: ID!, $to: ID!, $from: ID!, $message: String!) {
  createMessage(gameId: $gameId, to: $to, from: $from, message: $message) {
    gameId
    to
    from
    created
    message
  }
}
`;
