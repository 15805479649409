// tslint:disable
// this is an auto generated file. This will be overwritten

export const updatedGame = `subscription UpdatedGame($id: ID!) {
  updatedGame(id: $id) {
    id
    version
    turn
    created
    lastUpdated
    players {
      id
      name
      alias
      state {
        team
        room
        living
        ready
        nextTurn {
          actionType
          nextRoom
          hitList
          airlockList
        }
        turnSummary
      }
    }
    rooms {
      room
      damage
      lastScanDamage
    }
    shipHealth
    scansRemaining
    lastScanTurn
    gameStatus
    turnSummary
    config {
      minPlayers
      numTraitors
      numCrew
      numTurns
      numScans
      numRooms
      maxHealth
      startingDamage
    }
  }
}
`;
export const newMessage = `subscription NewMessage($gameId: ID!) {
  newMessage(gameId: $gameId) {
    gameId
    to
    from
    created
    message
  }
}
`;
