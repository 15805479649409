import React, { useLayoutEffect } from 'react';

import { CreateGame } from './components/CreateGame';
import { AddPlayer } from './components/AddPlayer';
import { PlayGame } from './components/PlayGame';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import WebFont from 'webfontloader';
import { ThemeProvider } from '@rmwc/theme';

import '@rmwc/icon/icon.css';
import '@material/button/dist/mdc.button.css';
import '@material/card/dist/mdc.card.css';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/chips/dist/mdc.chips.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/linear-progress/dist/mdc.linear-progress.css';
import '@material/list/dist/mdc.list.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/radio/dist/mdc.radio.css';
import '@material/select/dist/mdc.select.css';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/typography/dist/mdc.typography.css';
import '@rmwc/circular-progress/circular-progress.css';
import '@rmwc/data-table/data-table.css';

import './App.css';

WebFont.load({
  google: {
    families: ['Material Icons'],
  },
});

const updateSize = (): void => {
  // unless we wait a bit, rotating on a mobile doesn't reisze reliably
  setTimeout(() => {
    // first we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // fhen we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, 100);
};

const App: React.FC = () => {
  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
  }, []);

  return (
    <ThemeProvider
      options={{
        primary: '#003554',
        secondary: '#769FB6',
      }}
    >
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={CreateGame} />
          </Switch>
          <Switch>
            <Route exact path="/addPlayer" component={AddPlayer} />
          </Switch>
          <Switch>
            <Route exact path="/play" component={PlayGame} />
          </Switch>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default App;
