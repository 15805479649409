import React from 'react';
import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { Chip, ChipSet } from '@rmwc/chip';
import { LinearProgress } from '@rmwc/linear-progress';
import { ListDivider } from '@rmwc/list';
import { Typography } from '@rmwc/typography';
import { GetGameQuery } from '../../API';
import { AppSyncClient } from '../../util/appsync';
import { Logo } from '../../components/Logo';
import QRCode from 'qrcode.react';

type Game = GetGameQuery['getGame'];

const PreGame: React.FC<{ game: Game }> = props => {
  const { game } = props;
  const gameUrl = `${window.location.origin}/addPlayer?gameId=${game.id}`;
  const numPlayers = game.players.length;

  const doStartGame = (): void => {
    AppSyncClient.mutateStartGame(game.id).then(response => {
      console.log(response);
    });
  };

  return (
    <div className="config-panel">
      <div className="logo spaced">
        <Logo />
      </div>
      <Card>
        <ChipSet>
          {game.players.map(player => (
            <Chip key={player.id}>{player.name}</Chip>
          ))}
        </ChipSet>
        <div>
          <LinearProgress progress={numPlayers / game.config.minPlayers} />
          <div className="centered">
            <Typography use="subtitle1">
              {numPlayers} / {game.config.minPlayers}
            </Typography>
          </div>
          {numPlayers === game.config.minPlayers && (
            <div>
              <Button raised label="Play!" onClick={(): void => doStartGame()} />
            </div>
          )}
        </div>
        {numPlayers < game.config.minPlayers && (
          <>
            <ListDivider />
            <div>
              <Typography use="body1">
                You need {game.config.minPlayers} players to start, other players can join this game by scanning the QR
                code below, or by going <a href={gameUrl}>here</a>.
              </Typography>
            </div>
            <ListDivider />
            <div className="centered">
              <QRCode value={gameUrl} size={200} />
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export { PreGame };
